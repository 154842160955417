import Vue from 'vue';
import VueRouter from 'vue-router';
import RedisMonitor from '../views/RedisMonitor.vue';
import SitesAvailabilities from '../views/SitesAvailabilities';
import NotFound from '../views/NotFound.vue';
import Logout from '../views/LogoutItem.vue';
import Tooltip from 'hsy-vue-tooltip';
import Auth from '@okta/okta-vue';

Vue.use(VueRouter);
Vue.use(Tooltip);
Vue.use(Auth, {
  issuer: process.env.VUE_APP_OKTA_ISSUER,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URL,
  scopes: [process.env.VUE_APP_OKTA_SCOPES],
  pkce: true
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RedisMonitor,
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: '/implicit/callback', 
    component: Auth.handleCallback() 
  },
  {
    path: '/redismonitor',
    name: 'Redis Monitor',
    component: RedisMonitor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sitesavailabilities',
    name: 'Sites Availabilities',
    component: SitesAvailabilities,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logoutItem',
    name: 'Logout',
    component: Logout
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

export default router;
