var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "redis-body" }, [
    _c("div", { staticClass: "progress-bar-container" }, [
      _c("progress", {
        ref: "loadingBar",
        staticClass: "progress is-small is-primary",
        staticStyle: { display: "none" },
        attrs: { max: "100" },
      }),
    ]),
    _c("div", { staticClass: "redisConf" }, [
      _c("div", { staticClass: "search-site-container" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSite,
              expression: "currentSite",
            },
          ],
          staticClass: "tag-textfield input is-small is-primary",
          attrs: {
            id: "siteText",
            type: "text",
            placeholder: "Site name (ex: WIF_NLISO).",
          },
          domProps: { value: _vm.currentSite },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.researchBySite.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.currentSite = $event.target.value
            },
          },
        }),
        _c("input", {
          staticClass: "button is-small is-success",
          attrs: { type: "button", value: "Display" },
          on: {
            click: function ($event) {
              return _vm.researchBySite()
            },
          },
        }),
      ]),
      _c("div", { staticClass: "refresh-group" }, [
        _c("span", { staticClass: "tag is-info" }, [
          _vm._v(
            _vm._s(
              _vm.lastRefreshtime.toLocaleString({
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit",
                second: "numeric",
                timeZoneName: "short",
              })
            ) +
              " (" +
              _vm._s(_vm.globalTagsListToDisplay.length) +
              " tag(s) displayed) - channel : " +
              _vm._s(_vm.currentChannel)
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "redis-result" }, [
      _c("div", { staticClass: "list-title-head" }, [
        _c("h3", [_vm._v("Selected tags")]),
        _c(
          "div",
          {
            staticClass: "button is-small is-success",
            on: {
              click: function ($event) {
                return _vm.refreshSelectedListManualy()
              },
            },
          },
          [_c("font-awesome-icon", { attrs: { icon: "sync-alt" } })],
          1
        ),
        _c("span", { staticClass: "tag is-info" }, [
          _vm._v(
            "Most recent tag source date: " +
              _vm._s(
                _vm.mostRecentlySelectedDate?.toLocaleString({
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  second: "numeric",
                  timeZoneName: "short",
                })
              )
          ),
        ]),
        _c("label", { staticClass: "checkbox" }, [
          _c("input", {
            ref: "autorefreshCheck",
            attrs: { type: "checkbox" },
            on: {
              change: function ($event) {
                return _vm.autoRefresh()
              },
            },
          }),
          _vm._v(" Auto refresh "),
        ]),
      ]),
      _c("table", { staticClass: "table is-striped is-hoverable" }, [
        _c("caption"),
        _c("thead", [
          _c(
            "th",
            {
              staticClass: "sortable-column raw-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.RAW_NAME,
                    _vm.TableName.SELECTED,
                    false
                  )
                },
              },
            },
            [_vm._v("Source tag")]
          ),
          _c(
            "th",
            {
              staticClass: "sortable-column preproc-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.PREPROC_NAME,
                    _vm.TableName.SELECTED,
                    false
                  )
                },
              },
            },
            [_vm._v("Preproc tag")]
          ),
          _c(
            "th",
            {
              staticClass: "sortable-column realtime-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.REALTIME_NAME,
                    _vm.TableName.SELECTED,
                    false
                  )
                },
              },
            },
            [_vm._v("Realtime tag")]
          ),
          _c("th", { staticClass: "action-column" }, [_vm._v("Action")]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.selectedTagListToDisplay, function (result, index) {
            return _c(
              "tr",
              { key: result.preprocTag.name, staticClass: "tab-body-item" },
              [
                _c("td", { staticClass: "tab-result-tag-raw" }, [
                  _c(
                    "p",
                    {
                      staticClass: "tag-label",
                      attrs: { title: result.sourceTag.name },
                    },
                    [_vm._v(" " + _vm._s(result.sourceTag.name) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "tag-value",
                      attrs: {
                        title:
                          result.tagSite +
                          " - " +
                          result.sourceTag.date?.toLocaleString({
                            day: "numeric",
                            month: "numeric",
                            year: "2-digit",
                            hour: "numeric",
                            minute: "2-digit",
                            second: "numeric",
                          }) +
                          " - " +
                          _vm.valueFormat(result.sourceTag.val),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(result.tagSite) +
                          " - " +
                          _vm._s(
                            result.sourceTag.date?.toLocaleString({
                              day: "numeric",
                              month: "numeric",
                              year: "2-digit",
                              hour: "numeric",
                              minute: "2-digit",
                              second: "numeric",
                            })
                          ) +
                          " - " +
                          _vm._s(_vm.valueFormat(result.sourceTag.val)) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "tab-result-tag-preproc" }, [
                  _c(
                    "p",
                    {
                      staticClass: "tag-label",
                      attrs: { title: result.preprocTag.name },
                    },
                    [_vm._v(" " + _vm._s(result.preprocTag.name) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "tag-value",
                      attrs: { title: result.preprocTag.val },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.valueFormat(result.preprocTag.val)) +
                          " "
                      ),
                    ]
                  ),
                ]),
                result.realtimeTag.name !== null
                  ? _c("td", { staticClass: "tab-result-tag-realtime" }, [
                      _c(
                        "p",
                        {
                          staticClass: "tag-label",
                          attrs: { title: result.realtimeTag.name },
                        },
                        [_vm._v(" " + _vm._s(result.realtimeTag.name) + " ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "tag-value",
                          attrs: { title: result.realtimeTag.val },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.valueFormat(result.realtimeTag.val)) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _c(
                      "td",
                      { staticClass: "tab-result-tag-realtime error-text" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "tag-label",
                            attrs: { title: _vm.Unknow },
                          },
                          [_vm._v(" Unknow ")]
                        ),
                        _c("p", { staticClass: "tag-value" }, [_vm._v("-")]),
                      ]
                    ),
                _c("td", [
                  _c("input", {
                    staticClass: "removeTag button is-xsmall is-danger",
                    attrs: { type: "button", value: "X" },
                    on: {
                      click: function ($event) {
                        return _vm.removeSelectedTag(
                          index,
                          result.preprocTag.name
                        )
                      },
                    },
                  }),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "list-title-head" }, [
        _c("h3", [_vm._v("Global tags list")]),
        _c(
          "div",
          {
            staticClass: "button is-small is-success",
            on: {
              click: function ($event) {
                return _vm.RefreshGlobalTagListManualy()
              },
            },
          },
          [_c("font-awesome-icon", { attrs: { icon: "sync-alt" } })],
          1
        ),
        _c("div", { staticClass: "search-tag-container" }, [
          _c("span", { staticClass: "icon" }, [
            _c(
              "svg",
              {
                staticStyle: { width: "24px", height: "24px" },
                attrs: { viewBox: "0 0 24 24" },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z",
                  },
                }),
              ]
            ),
          ]),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentTag,
                  expression: "currentTag",
                },
              ],
              staticClass: "site-textfield",
              attrs: {
                id: "site-search-txtfld",
                type: "text",
                placeholder: "Tag name...",
              },
              domProps: { value: _vm.currentTag },
              on: {
                keyup: function ($event) {
                  return _vm.filterGlobalTagsListToDisplay()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.currentTag = $event.target.value
                },
              },
            }),
            _c("input", {
              staticClass: "clr-global-site-search",
              attrs: { type: "button", value: "x" },
              on: {
                click: function ($event) {
                  return _vm.clearGlobalListSearch()
                },
              },
            }),
          ]),
        ]),
        _c("span", { staticClass: "tag is-info" }, [
          _vm._v(
            "Most recent tag source date: " +
              _vm._s(
                _vm.mostRecentlyGlobalDate?.toLocaleString({
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  second: "numeric",
                  timeZoneName: "short",
                })
              )
          ),
        ]),
      ]),
      _c("table", { staticClass: "table is-striped is-hoverable" }, [
        _c("caption"),
        _c("thead", [
          _c(
            "th",
            {
              staticClass: "sortable-column raw-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.RAW_NAME,
                    _vm.TableName.GLOBAL,
                    false
                  )
                },
              },
            },
            [_vm._v("Source tag")]
          ),
          _c(
            "th",
            {
              staticClass: "sortable-column preproc-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.PREPROC_NAME,
                    _vm.TableName.GLOBAL,
                    false
                  )
                },
              },
            },
            [_vm._v("Preproc tag")]
          ),
          _c(
            "th",
            {
              staticClass: "sortable-column realtime-column",
              on: {
                click: function ($event) {
                  return _vm.sortColumn(
                    _vm.RedisColumnName.REALTIME_NAME,
                    _vm.TableName.GLOBAL,
                    false
                  )
                },
              },
            },
            [_vm._v("Realtime tag")]
          ),
          _c("th", { staticClass: "action-column" }, [_vm._v("Action")]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.globalTagsListToDisplay, function (result, index) {
            return _c(
              "tr",
              { key: result.preprocTag.name, staticClass: "tab-body-item" },
              [
                _c("td", { staticClass: "tab-result-tag-raw" }, [
                  _c(
                    "p",
                    {
                      staticClass: "tag-label",
                      attrs: { title: result.sourceTag.name },
                    },
                    [_vm._v(" " + _vm._s(result.sourceTag.name) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "tag-value",
                      attrs: {
                        title:
                          result.sourceTag.date?.toLocaleString({
                            day: "numeric",
                            month: "numeric",
                            year: "2-digit",
                            hour: "numeric",
                            minute: "2-digit",
                            second: "numeric",
                          }) +
                          " - " +
                          _vm.valueFormat(result.sourceTag.val),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            result.sourceTag.date?.toLocaleString({
                              day: "numeric",
                              month: "numeric",
                              year: "2-digit",
                              hour: "numeric",
                              minute: "2-digit",
                              second: "numeric",
                            })
                          ) +
                          " - " +
                          _vm._s(_vm.valueFormat(result.sourceTag.val)) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("td", { staticClass: "tab-result-tag-preproc" }, [
                  _c(
                    "p",
                    {
                      staticClass: "tag-label",
                      attrs: { title: result.preprocTag.name },
                    },
                    [_vm._v(" " + _vm._s(result.preprocTag.name) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "tag-value",
                      attrs: { title: _vm.valueFormat(result.preprocTag.val) },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.valueFormat(result.preprocTag.val)) +
                          " "
                      ),
                    ]
                  ),
                ]),
                result.realtimeTag.name !== null
                  ? _c("td", { staticClass: "tab-result-tag-realtime" }, [
                      _c(
                        "p",
                        {
                          staticClass: "tag-label",
                          attrs: { title: result.realtimeTag.name },
                        },
                        [_vm._v(" " + _vm._s(result.realtimeTag.name) + " ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "tag-value",
                          attrs: { title: result.realtimeTag.val },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.valueFormat(result.realtimeTag.val)) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _c(
                      "td",
                      {
                        staticClass: "tab-result-tag-realtime error-text",
                        attrs: { title: _vm.Unknow },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "tag-label",
                            attrs: { title: _vm.Unknow },
                          },
                          [_vm._v(" Unknow ")]
                        ),
                        _c("p", { staticClass: "tag-value" }, [_vm._v(" - ")]),
                      ]
                    ),
                _c("td", [
                  _c("input", {
                    staticClass: "button is-xsmall is-success",
                    attrs: { type: "button", value: "Select" },
                    on: {
                      click: function ($event) {
                        return _vm.addSelectedTag(index, result.preprocTag.name)
                      },
                    },
                  }),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }