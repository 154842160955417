<template>
  <div class="body-template-root">
    <app-header></app-header>
    <section class="main-section section">
      <div class="container content">
        <router-view></router-view>
      </div>
    </section>
    <notifications position="top right" width="500px" group="foo" />
  </div>
</template>
<script>
import AppHeader from './components/AppHeader.vue'

export default {
  components: {
      'app-header': AppHeader
  },
  name: 'app',
}
</script>
<style lang="scss">
$primary: #287ab1;
@import '~bulma';

html {
  height: 100%;
  width: 100%;
    overflow: hidden;

  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    
  > .body-template-root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .nav {
      width: 100%;
      height: 80px;
      background-color: $primary;

      a {
        color: #ffffff;
      }

      .container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .nav-item {
          font-size: 2em;
          margin-left: 20px;
        }

        button {
          margin-left: 10px;
        }
      }
    }

    .main-section {
      width: 100%;
      height: 100%;
      padding: 0;

      .container.content {
        height: 100%;
        width: 100%;
        max-width: none;
      }
      .columns{
        flex-wrap: wrap
      }
    }

    .footer {
      padding: 10px;
      width: 100%;
      height: 70px;
    }
  }
  }

}
</style>
