import Vue from 'vue';
import axios from 'axios';
import {siteType} from '../../Utilities/Enum.js';
import { DateTime as LuxonDateTime } from "luxon";
import dateTimeLuxon from '../time/dateTimeLuxon.js';

const defaultErrorMessage = "Server error, try again. If error persists contact an administrator";
const contentType = "application/json";

const redisApi = {
  async getTagsValuesWithoutSiteName(tags, accessToken) {
    let returnEndTagsValue = [];

    try {
      let tagBySitesDic = [];
      tags.forEach(tag => {
        if((tagBySitesDic.filter(tagSite => tagSite.key === tag.tagSite )).length === 0 || (tagBySitesDic === undefined || tagBySitesDic.length === 0)){
          tagBySitesDic.push({key: tag.tagSite, value: []});
        }
        tagBySitesDic[tagBySitesDic.findIndex(t => t.key === tag.tagSite)].value.push(tag);
      });
      
      for (const currentSite of tagBySitesDic) {
        let sourceTagSite = await this.getSourceTagsFromSite(currentSite.key, accessToken);
        let returnEndTagsValueTemp = await this.getTagsValues(currentSite.value,sourceTagSite,accessToken);
        for (const t of returnEndTagsValueTemp) {
          returnEndTagsValue.push(t);
        }
      }

      return returnEndTagsValue;

    } catch (error) {
      Vue.notify({
        group: 'foo',
        title: "Tags values without site name error",
        text: defaultErrorMessage,
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        type: 'error'
      });
      return null;
    }
  },
  async getSourceTagsFromSite(site, accessToken) {
    // Get sources tags for site
    let sourceTags = [];
    let sourceTagMap = [];
    try{
      const sourceTagsUrl = `${process.env.VUE_APP_URL_TAG_VALUE_API}/Raw__${site.toUpperCase()}`;

      const sourceTagsResult = await axios.get(
      sourceTagsUrl,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          'Content-Type': contentType,
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
          'Ocp-Apim-Trace': true
        }
      });
      sourceTags = JSON.parse(sourceTagsResult.data.value).t;
      
      for (var key in sourceTags) {
          var obj = sourceTags[key];
          var currentDate = dateTimeLuxon.baseFormat(obj.d);
          sourceTagMap.push({n: key, v: obj.v, d: currentDate});
        } 
      return sourceTagMap;
    } 
    catch(err){
      Vue.notify({
        group: 'foo',
        title: "Get site tags error",
        text: "Site source tags not found",
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        type: 'error'
      });
    }
  },
  async getTagsValues (tags, sourceTagMap, accessToken) {
    const realtimeTags = tags.map(a => a.realtimeTag.name).filter((obj) => obj);
    const preprocTags = tags.map(a => a.preprocTag.name);
    const realtimeResult = await this.getRealtimeRedisTagsValues(realtimeTags, accessToken);
    const preProcResult = await this.getPreprocRedisTagsValues(preprocTags, accessToken);
  
    try{
      const endTagsValue = [];

      for (const tageResult of tags) {
        const preprocObject = preProcResult.find(p => p.name === tageResult.preprocTag.name);
        const sourceTagRef = sourceTagMap.find(t => t.n === tageResult.sourceTag.name);
        let sourceTagRefName = null;
        let sourceTagrefValue = null;
        let sourceTagrefDate = null;
        if (sourceTagRef !== undefined) {
          sourceTagRefName = sourceTagRef.n;
          sourceTagrefValue = sourceTagRef.v;
          sourceTagrefDate = sourceTagRef.d;
        }
        if(tageResult.realtimeTag.name !== null && tageResult.realtimeTag.name !== ""){
          const realtimeObject = realtimeResult.find(p => p.name === tageResult.realtimeTag.name);
          endTagsValue.push({
            sourceTag: {
              name: sourceTagRefName,
              val: sourceTagrefValue,
              date: sourceTagrefDate
            },
            preprocTag: {
              name: preprocObject.name,
              val: preprocObject.value
            },
            realtimeTag: {
              name: realtimeObject.name,
              val: realtimeObject.value
            },
            onError: false,
            tagSite : tageResult.tagSite
          });
        }else{
          endTagsValue.push({
            sourceTag: {
              name: sourceTagRefName,
              val: sourceTagrefValue,
              date: sourceTagrefDate
            },
            preprocTag: {
              name: preprocObject.name,
              val: preprocObject.value
            },
            realtimeTag: {
              name: null,
              val: null
            },
              onError: true,
              tagSite : tageResult.tagSite
          });
        }
      }
      return endTagsValue;
    }catch(err) {
      Vue.notify({
        group: 'foo',
        title: "Tags values error",
        text: defaultErrorMessage,
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        type: 'error'
      });
      return null;
    }
  },
  async getTagsMapping (tags, accessToken) {
    const tagsResult= [];

    for (const tag of tags) {
      if(tag === ""){continue;}
      const url = `${process.env.VUE_APP_URL_TAG_DEFINITION_API}?translatedTagName=${tag.preprocTag.name}`;
      await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            'Content-Type': contentType,
            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
            'Ocp-Apim-Trace': true
          }
        }).then(tagResult => {
          let realtimeName = null;
          const sourceTagName = tagResult.data[0] ? tagResult.data[0]["TagName"] : null;
          
          if (tagResult.data.length > 0){
            if ( tagResult.data[0]['RealtimeTagName'] && tagResult.data[0]['RealtimeTagName'] !== null && tagResult.data[0]['RealtimeTagName'] !== "") {
              realtimeName = tagResult.data[0]['RealtimeTagName'];
            }
            else {
              realtimeName = tagResult.data[0]['TranslatedTagName'];
            }
            tagsResult.push({
              sourceTag: {
                name: sourceTagName,
                val: null,
                date: null
              },
              preprocTag: {
                name: tag.preprocTag.name,
                val: null
              },
              realtimeTag: {
                name: realtimeName,
                val: null
              },
              onError: false,
              tagSite: tag.tagSite
            });
          }else{
            tagsResult.push({
              sourceTag: {
                name: sourceTagName,
                val: null,
                date: null
              },
              preprocTag: {
                name: tag.preprocTag.name,
                val: null
              },
              realtimeTag: {
                name: tag.realtimeTag.name,
                val: null
              },
              onError: true,
              tagSite: tag.tagSite
            });
          }
        });
    }
    return tagsResult;
  },
  async getPreprocRedisTagsValues(tags, accessToken) {
    try{
      let preprocTagsResult= [];
      const cacheResult = await axios.post(
      process.env.VUE_APP_URL_TAG_VALUES_API,
      tags,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          'Content-Type': contentType,
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
          'Ocp-Apim-Trace': true
        }
      });
        preprocTagsResult = cacheResult.data;
      return preprocTagsResult;
    }catch(err){
      if (err.response && err.response.status === 500) {
        Vue.notify({
          group: 'foo',
          title: "Tag retrieval error",
          text: defaultErrorMessage,
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }else{
        Vue.notify({
          group: 'foo',
          title: "Global error",
          text: "Retry again. If error persists contact an administrator",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }
    }
    
  },
  async getRealtimeRedisTagsValues(tags, accessToken) {
    try{
      let realtimeTagsResult= [];
      const cacheResult = await axios.post(
        process.env.VUE_APP_URL_TAG_VALUES_API_REALTIME,
        tags,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            'Content-Type': contentType,
            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
            'Ocp-Apim-Trace': true
          }
        }
      );
      realtimeTagsResult = cacheResult.data;
      return realtimeTagsResult;
    }catch(err){
      if (err.response && err.response.status === 500) {
        Vue.notify({
          group: 'foo',
          title: "Tag retrieval error",
          text: defaultErrorMessage,
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }else{
        Vue.notify({
          group: 'foo',
          title: "Global error",
          text: "Retry again. If error persists contact an administrator",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }
    }
  },
  async getSiteTagMapping (site, accessToken) {
      // Get preproc and realtime tags name
    try {
      let tagsResult= [];
      const url = `${process.env.VUE_APP_URL_TAG_NAMES_FROM_SITE_API}?tagSite=${site}&includeInternals=true`;
      const siteTagsResult = await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            'Content-Type': contentType,
            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
            'Ocp-Apim-Trace': true
          }
        });
        tagsResult = siteTagsResult.data;

        var tagNames = tagsResult.map(function(item) {
          let realtimeName = null; 
          if (item['RealtimeTagName'] !== null && item['RealtimeTagName'] !== "") {
            realtimeName = item['RealtimeTagName'];
          }
          else {
            realtimeName = item['TranslatedTagName'];
          }

          return {
            sourceTag: {
              name: item['SourceTagName'],
              val: null,
              date: null
            },
            preprocTag: {
              name: item['TranslatedTagName'],
              val: null
            },
            realtimeTag: {
              name: realtimeName,
              val: null
            },
            onError: false,
            tagSite: site.toUpperCase()
          };
        });

        return tagNames;
    } 
    catch(err){
        Vue.notify({
          group: 'foo',
          title: "Get site tags error",
          text: "Site tags not found",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }
  },
  async getActiveSites (accessToken) {
          let returnResult = [];
    const url = process.env.VUE_APP_ACTIVE_SITES_API;
      await axios.get(
        url,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            'Content-Type': contentType,
            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
            'Ocp-Apim-Trace': true
          }
        }).then(siteResults => {
          for (const site of siteResults.data) {
            const tagName = site.concat(process.env.VUE_APP_LIFEWORD_TAG_NAME);
            let siteTypeValue = null;
            switch (site.substring(0, 3)) {
              case "WIF":
                siteTypeValue = siteType.WIND;
              break;
              case "SOF":
                siteTypeValue = siteType.SOLAR;
              break;
              case "BIF":
                siteTypeValue = siteType.BIOGAZ;
              break;
              case "HYG":
                siteTypeValue = siteType.HYGRO;
              break;
              default:
                break;
            }
            returnResult.push({
              name: site,
              tagName: tagName,
              type: siteTypeValue,
              lastActivityTimestamp: null,
              lastActivity: null,
              onError: null
            });
          }
        });
          return returnResult;
  },
  async getSiteAvalabilities (siteResults, accessToken) {
          let returnResult = [];
          const HALF_HOUR_IN_SEC = 30 * 60;

          await this.getPreprocRedisTagsValues(siteResults.map(m => m.tagName), accessToken)
          .then(siteLWResult => {
            for (const siteresult of siteResults) {
              const lifeWordTimestampValue =  siteLWResult.find(p => p.name === siteresult.tagName);
              siteresult.lastActivityTimestamp = lifeWordTimestampValue.value;
              siteresult.lastActivity = LuxonDateTime.fromMillis(lifeWordTimestampValue.value).toUtc().toformat("DD/MM/YYYY HH:mm:ss");
              const dateNowTimestamp = Math.floor(Date.now()/1000);

              const gapTime = dateNowTimestamp - (lifeWordTimestampValue.value);

              if (gapTime > HALF_HOUR_IN_SEC) {
                siteresult.onError = true;
              }
              else {
                if (lifeWordTimestampValue.value > dateNowTimestamp) {
                  siteresult.onError = null;
                } else {
                  siteresult.onError = false;
                }
              }

              returnResult.push(siteresult);
            }
            return returnResult;
          });
          
          
  },
  async getSiteDetailsFromSite(site, accessToken) {
    try{
      let siteDetails= [];
      const siteDetailsUrl = `${process.env.VUE_APP_URL_SITE_DETAILS_API}?code=${site.toUpperCase()}`;
      const siteDetailsResult = await axios.get(
        siteDetailsUrl,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': process.env.VUE_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          'Content-Type': contentType,
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_OCP_APIM_SUBSCRIPTION_KEY_MONITORING,
          'Ocp-Apim-Trace': true
        }
      });
      siteDetails = siteDetailsResult.data;
      return siteDetails;

    }catch(err){
      if (err.response && err.response.status === 500) {
        Vue.notify({
          group: 'foo',
          title: "Site details error",
          text: defaultErrorMessage,
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }else{
        Vue.notify({
          group: 'foo',
          title: "Global error",
          text: "Retry again. If error persists contact an administrator",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          type: 'error'
        });
      }
    }
    
  }
}

export default redisApi;
