var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "avalability" }, [
    _c("div", { staticClass: "progress-bar-container" }, [
      _c("progress", {
        ref: "loadingBar",
        staticClass: "progress is-small is-primary",
        staticStyle: { display: "none" },
        attrs: { max: "100" },
      }),
    ]),
    _c("div", { ref: "multipleSites", staticClass: "textfield-container" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentSites,
            expression: "currentSites",
          },
        ],
        staticClass: "textarea input is-primary",
        attrs: {
          placeholder:
            "Add multiple sites: separate by ';' or new line and add it with 'Display' button (ex: WIF_ERPAS;SOF_PESLD;...)",
        },
        domProps: { value: _vm.currentSites },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.currentSites = $event.target.value
          },
        },
      }),
      _c("input", {
        staticClass: "button is-success",
        attrs: { type: "button", value: "Display" },
        on: {
          click: function ($event) {
            return _vm.selectSiteList()
          },
        },
      }),
    ]),
    _c("div", { staticClass: "avalability-header" }, [
      _c("div", { staticClass: "list-search" }, [
        _c("span", { staticClass: "icon" }, [
          _c(
            "svg",
            {
              staticStyle: { width: "24px", height: "24px" },
              attrs: { viewBox: "0 0 24 24" },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z",
                },
              }),
            ]
          ),
        ]),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.globalListSearch,
                expression: "globalListSearch",
              },
            ],
            staticClass: "site-textfield",
            attrs: {
              id: "site-search-txtfld",
              type: "text",
              placeholder: "Site name...",
            },
            domProps: { value: _vm.globalListSearch },
            on: {
              keyup: function ($event) {
                return _vm.ListFilterBySiteType()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.globalListSearch = $event.target.value
              },
            },
          }),
          _c("input", {
            staticClass: "clr-global-site-search",
            attrs: { type: "button", value: "x" },
            on: {
              click: function ($event) {
                return _vm.clearGlobalListSearch()
              },
            },
          }),
        ]),
        _c("ul", { staticClass: "type-filter" }, [
          _c(
            "li",
            {
              staticClass: "category-item selected all",
              on: {
                click: function ($event) {
                  return _vm.selectSiteType($event, _vm.siteType.ALL)
                },
              },
            },
            [_c("p", [_vm._v("All")])]
          ),
          _c(
            "li",
            {
              staticClass: "category-item wind",
              on: {
                click: function ($event) {
                  return _vm.selectSiteType($event, _vm.siteType.WIND)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "material-icons",
                  attrs: { viewBox: "0 0 200 150" },
                },
                [
                  _c("path", {
                    staticClass: "blue",
                    attrs: {
                      transform: "translate(89 92)",
                      d: "M12.439.43a4.085 4.085 0 0 0-3.644 0L4.381 2.67 0 107.93h21.236L16.855 2.662 12.439.43z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#425B67",
                      d: "M102.183 77.699c-.776.776-1.84 1.227-2.901 1.227-1.064 0-2.125-.451-2.902-1.227a4.114 4.114 0 0 1-1.185-2.86c0-1.103.449-2.125 1.185-2.9 1.553-1.513 4.291-1.513 5.803 0a4.043 4.043 0 0 1 1.186 2.9c0 1.062-.41 2.126-1.186 2.86zm61.53 27.896l-54.342-35.32a4.082 4.082 0 0 1-1.822-3.157l-3.472-64.766A2.484 2.484 0 0 0 101.597 0h-4.615a2.485 2.485 0 0 0-2.481 2.352l-3.472 64.766a4.086 4.086 0 0 1-1.822 3.157l-54.08 35.318a2.483 2.483 0 0 0-.794 3.323l2.275 3.942a2.484 2.484 0 0 0 3.278.973l57.58-29.251a4.079 4.079 0 0 1 3.644 0l57.853 29.257a2.485 2.485 0 0 0 3.274-.975l2.274-3.942a2.484 2.484 0 0 0-.798-3.325z",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "li",
            {
              staticClass: "category-item solar",
              on: {
                click: function ($event) {
                  return _vm.selectSiteType($event, _vm.siteType.SOLAR)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "material-icons",
                  attrs: { viewBox: "0 0 200 200" },
                },
                [
                  _c("path", {
                    staticClass: "blue",
                    attrs: {
                      transform: "translate(26 144)",
                      d: "M6.584.232l-6 13.437h146.83l-6-13.437z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#425B67",
                      id: "rotor",
                      d: "M74.667 101.31h50.666c.324 0 .572-.34.525-.717l-2.42-19.403c-.04-.307-.261-.535-.525-.535H77.087c-.264 0-.487.228-.525.535l-2.42 19.403c-.048.377.2.717.525.717zm-60.13 0h50.609c.253 0 .472-.213.52-.507l3.21-19.402c.064-.386-.187-.746-.521-.746H22.588c-.203 0-.388.137-.478.351l-8.05 19.402c-.172.416.085.902.478.902zm10.579-25.495h44.247c.254 0 .473-.212.522-.507L72.34 60.47c.063-.387-.188-.747-.522-.747H31.272c-.203 0-.387.137-.477.352l-6.156 14.837c-.173.416.085.902.477.902zm34.966 58.745l4.333-26.193c.064-.387-.186-.747-.521-.747H11.399c-.202 0-.387.136-.476.35L.055 134.166c-.173.416.084.902.476.902h59.03c.253 0 .472-.213.52-.507zm139.863-.395l-10.868-26.194c-.09-.215-.274-.35-.477-.35h-52.495c-.334 0-.584.36-.521.746l4.334 26.193c.049.294.267.507.52.507h59.03c.392 0 .65-.486.477-.902zM33.382 55.894h39.277c.254 0 .473-.213.522-.506l1.925-11.641c.065-.387-.187-.747-.521-.747H38.212c-.203 0-.39.137-.478.351l-4.83 11.642c-.173.415.084.9.478.9zm94.278 4.577l2.454 14.837c.049.295.268.507.522.507h44.248c.392 0 .649-.486.476-.902l-6.156-14.837c-.09-.215-.274-.352-.476-.352h-40.546c-.334 0-.585.36-.522.747zm3.463 20.93l3.21 19.402c.049.294.268.507.522.507h50.607c.393 0 .649-.486.476-.902l-8.05-19.402c-.088-.214-.274-.351-.477-.351h-45.766c-.334 0-.585.36-.522.746zm-4.847 26.22H73.724c-.264 0-.488.227-.526.535l-3.267 26.193c-.047.378.2.718.525.718h59.088c.324 0 .572-.34.525-.718l-3.267-26.193c-.038-.308-.262-.536-.526-.536zM80.332 55.893h39.336c.324 0 .573-.34.525-.717l-1.452-11.642c-.038-.307-.261-.535-.525-.535H81.783c-.262 0-.486.228-.525.535l-1.452 11.642c-.047.377.202.717.526.717zm-2.485 19.92h44.305c.325 0 .574-.339.527-.717l-1.852-14.837c-.038-.307-.262-.536-.525-.536H79.697c-.263 0-.487.229-.525.536L77.32 75.097c-.047.378.2.718.526.718zm49.494-19.92h39.277c.393 0 .65-.486.477-.901l-4.83-11.642c-.09-.214-.274-.351-.477-.351h-36.374c-.334 0-.585.36-.52.747l1.925 11.64c.049.294.267.507.522.507z",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "li",
            {
              staticClass: "category-item hygro",
              on: {
                click: function ($event) {
                  return _vm.selectSiteType($event, _vm.siteType.HYGRO)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "material-icons",
                  attrs: { viewBox: "0 0 200 200" },
                },
                [
                  _c("path", {
                    staticClass: "blue",
                    attrs: {
                      transform: "translate(36 35)",
                      d: "M92.774 64.355a4.472 4.472 0 1 1 8.944 0 4.472 4.472 0 0 1-8.944 0M90.602 91.19a4.47 4.47 0 0 1-6.323 0 4.47 4.47 0 0 1 0-6.324 4.471 4.471 0 0 1 6.322 6.323m-26.835-7.43c-10.719 0-19.407-8.687-19.407-19.405 0-10.718 8.688-19.406 19.407-19.406 10.718 0 19.407 8.688 19.407 19.406 0 10.718-8.69 19.406-19.407 19.406m0 18.545a4.472 4.472 0 1 1 0-8.943 4.472 4.472 0 0 1 0 8.943M43.254 91.191a4.471 4.471 0 1 1-6.324-6.323 4.471 4.471 0 0 1 6.324 6.323m-6.325-53.672a4.473 4.473 0 0 1 6.324 0 4.472 4.472 0 1 1-6.323 0m-6.645 31.308a4.472 4.472 0 1 1 .001-8.944 4.472 4.472 0 0 1 0 8.944m33.48-42.424a4.472 4.472 0 1 1 0 8.944 4.472 4.472 0 0 1 0-8.944M84.278 37.52a4.471 4.471 0 1 1 6.324 6.323 4.471 4.471 0 0 1-6.324-6.323m26.103 26.836c0-25.745-20.87-46.615-46.615-46.615S17.15 38.61 17.15 64.355s20.87 46.616 46.615 46.616S110.38 90.1 110.38 64.355",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#425B67",
                      d: "M131.215 161.576c.304 1.772.471 3.592.472 5.451-.004 17.638-14.306 31.932-31.944 31.928l.008-29.883.002-15.876a54.261 54.261 0 0 0 24.932-6.118 31.777 31.777 0 0 1 6.53 14.498zm15.201-35.288a53.52 53.52 0 0 0 7.149-24.666 31.776 31.776 0 0 1 15.816 1.595 32.06 32.06 0 0 1 4.953 2.314c15.275 8.82 20.507 28.35 11.689 43.624l-39.607-22.867zM68.318 37.133a32.112 32.112 0 0 1-.472-5.45C67.85 14.045 82.151-.25 99.788-.246l-.006 29.884-.004 15.876a54.253 54.253 0 0 0-24.932 6.118 31.78 31.78 0 0 1-6.528-14.499zm78.088 35.323A53.477 53.477 0 0 0 128.654 53.9a31.782 31.782 0 0 1 9.29-12.885 32.129 32.129 0 0 1 4.481-3.13c15.28-8.811 34.809-3.569 43.62 11.71L160.16 64.522l-13.753 7.933zm-93.29-.034a53.521 53.521 0 0 0-7.149 24.666 31.78 31.78 0 0 1-15.816-1.595A32.057 32.057 0 0 1 25.2 93.18C9.924 84.36 4.69 64.83 13.509 49.555l39.607 22.867zm.01 53.832a53.486 53.486 0 0 0 17.753 18.556 31.79 31.79 0 0 1-9.29 12.884 32.1 32.1 0 0 1-4.483 3.13c-15.279 8.813-34.807 3.57-43.619-11.708l25.886-14.93 13.753-7.932z",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "li",
            {
              staticClass: "category-item biogaz",
              on: {
                click: function ($event) {
                  return _vm.selectSiteType($event, _vm.siteType.BIOGAZ)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "material-icons",
                  attrs: { viewBox: "0 0 24 24" },
                },
                [
                  _c("rect", {
                    attrs: {
                      fill: "#BDD52A",
                      x: "0.2",
                      y: "13",
                      width: "23.8",
                      height: "11",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#415B68",
                      d: "M13.4,1.2V0h-3v1.2C3.4,1.7,0,6.6,0,6.6V24h24V6.6C24,6.6,20.8,1.6,13.4,1.2z M23.4,23.4H0.6V7.2h22.8V23.4z",
                    },
                  }),
                  _c("g", { attrs: { transform: "translate(0, 3.5)" } }, [
                    _c("g", { attrs: { fill: "#00A8FF" } }, [
                      _c("g", { attrs: { transform: "translate(12, 12)" } }, [
                        _c("path", {
                          attrs: {
                            d: "M-2.4,0.9c0-0.2,0-0.3,0.1-0.3l1.1-0.4l-2.7-1.3l-1.4,2.8l1-0.4c0.1-0.1,0.2,0,0.3,0.1c0.5,1,1.2,1.8,2.2,2.2 C-1.1,3.9-0.2,4,0.8,3.8C0.1,3.7-0.5,3.4-1,3C-1.7,2.4-2.2,1.7-2.4,0.9z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M4.3-1.3C4.2-1.2,4.1-1.3,4-1.4c-0.5-1-1.2-1.8-2.2-2.2C1.1-3.9,0.2-4-0.8-3.8C-0.1-3.7,0.5-3.4,1-3 c0.7,0.6,1.2,1.3,1.4,2.1c0.1,0.2,0,0.3-0.1,0.3L1.2-0.2l2.7,1.3l1.4-2.8L4.3-1.3z",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "list-refresh" }, [
        _c("label", { staticClass: "is-size-7" }, [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.lastRefreshtime.toLocaleString({
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  second: "numeric",
                  timeZoneName: "short",
                })
              ) + " (utc)"
            ),
          ]),
          _vm._v(
            " (" +
              _vm._s(_vm.globalResultListDisplayed.length) +
              " site(s) displayed) "
          ),
        ]),
        _c("input", {
          staticClass: "button is-small is-success",
          attrs: { id: "refresh-btn", type: "button", value: "Refresh values" },
          on: {
            click: function ($event) {
              return _vm.refreshGlobalList()
            },
          },
        }),
        _c("label", { staticClass: "checkbox is-size-7" }, [
          _c("input", {
            ref: "availabilityAutorefreshCheck",
            staticClass: "chkbx-autorefresh",
            attrs: { type: "checkbox" },
            on: {
              change: function ($event) {
                return _vm.availabilityAutoRefresh()
              },
            },
          }),
          _vm._v(" Auto refresh "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "avalability-body" }, [
      _c("div", { staticClass: "lists-container" }, [
        _c("div", { staticClass: "result-list selected-list" }, [
          _c("table", { staticClass: "table" }, [
            _c("caption", [_vm._v("Selected sites availability")]),
            _c("thead", [
              _c("th", { staticClass: "is-1", attrs: { scope: "col" } }, [
                _vm._v("Type"),
              ]),
              _c(
                "th",
                {
                  staticClass: "is-4 sortable-column",
                  attrs: { scope: "col" },
                  on: {
                    click: function ($event) {
                      return _vm.sortColumn(_vm.ColumnName.NAME)
                    },
                  },
                },
                [_vm._v("Site name")]
              ),
              _c(
                "th",
                {
                  staticClass: "is-4 sortable-column",
                  attrs: { scope: "col" },
                  on: {
                    click: function ($event) {
                      return _vm.sortColumn(_vm.ColumnName.LAST_ACTIVITY)
                    },
                  },
                },
                [_vm._v("Last activity (utc)")]
              ),
              _c(
                "th",
                {
                  staticClass: "is-1 sortable-column",
                  attrs: { scope: "col" },
                  on: {
                    click: function ($event) {
                      return _vm.sortColumn(_vm.ColumnName.STATUS)
                    },
                  },
                },
                [_vm._v("Availability")]
              ),
              _c("th", { staticClass: "is-2", attrs: { scope: "col" } }, [
                _vm._v("Action"),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.selectedResultListDisplayed, function (result, index) {
                return _c("tr", { key: result.name }, [
                  _c("td", { attrs: { scope: "row" } }, [
                    result.type === _vm.siteType.SOLAR
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(26 144)",
                                d: "M6.584.232l-6 13.437h146.83l-6-13.437z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                id: "rotor",
                                d: "M74.667 101.31h50.666c.324 0 .572-.34.525-.717l-2.42-19.403c-.04-.307-.261-.535-.525-.535H77.087c-.264 0-.487.228-.525.535l-2.42 19.403c-.048.377.2.717.525.717zm-60.13 0h50.609c.253 0 .472-.213.52-.507l3.21-19.402c.064-.386-.187-.746-.521-.746H22.588c-.203 0-.388.137-.478.351l-8.05 19.402c-.172.416.085.902.478.902zm10.579-25.495h44.247c.254 0 .473-.212.522-.507L72.34 60.47c.063-.387-.188-.747-.522-.747H31.272c-.203 0-.387.137-.477.352l-6.156 14.837c-.173.416.085.902.477.902zm34.966 58.745l4.333-26.193c.064-.387-.186-.747-.521-.747H11.399c-.202 0-.387.136-.476.35L.055 134.166c-.173.416.084.902.476.902h59.03c.253 0 .472-.213.52-.507zm139.863-.395l-10.868-26.194c-.09-.215-.274-.35-.477-.35h-52.495c-.334 0-.584.36-.521.746l4.334 26.193c.049.294.267.507.52.507h59.03c.392 0 .65-.486.477-.902zM33.382 55.894h39.277c.254 0 .473-.213.522-.506l1.925-11.641c.065-.387-.187-.747-.521-.747H38.212c-.203 0-.39.137-.478.351l-4.83 11.642c-.173.415.084.9.478.9zm94.278 4.577l2.454 14.837c.049.295.268.507.522.507h44.248c.392 0 .649-.486.476-.902l-6.156-14.837c-.09-.215-.274-.352-.476-.352h-40.546c-.334 0-.585.36-.522.747zm3.463 20.93l3.21 19.402c.049.294.268.507.522.507h50.607c.393 0 .649-.486.476-.902l-8.05-19.402c-.088-.214-.274-.351-.477-.351h-45.766c-.334 0-.585.36-.522.746zm-4.847 26.22H73.724c-.264 0-.488.227-.526.535l-3.267 26.193c-.047.378.2.718.525.718h59.088c.324 0 .572-.34.525-.718l-3.267-26.193c-.038-.308-.262-.536-.526-.536zM80.332 55.893h39.336c.324 0 .573-.34.525-.717l-1.452-11.642c-.038-.307-.261-.535-.525-.535H81.783c-.262 0-.486.228-.525.535l-1.452 11.642c-.047.377.202.717.526.717zm-2.485 19.92h44.305c.325 0 .574-.339.527-.717l-1.852-14.837c-.038-.307-.262-.536-.525-.536H79.697c-.263 0-.487.229-.525.536L77.32 75.097c-.047.378.2.718.526.718zm49.494-19.92h39.277c.393 0 .65-.486.477-.901l-4.83-11.642c-.09-.214-.274-.351-.477-.351h-36.374c-.334 0-.585.36-.52.747l1.925 11.64c.049.294.267.507.522.507z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.HYGRO
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(36 35)",
                                d: "M92.774 64.355a4.472 4.472 0 1 1 8.944 0 4.472 4.472 0 0 1-8.944 0M90.602 91.19a4.47 4.47 0 0 1-6.323 0 4.47 4.47 0 0 1 0-6.324 4.471 4.471 0 0 1 6.322 6.323m-26.835-7.43c-10.719 0-19.407-8.687-19.407-19.405 0-10.718 8.688-19.406 19.407-19.406 10.718 0 19.407 8.688 19.407 19.406 0 10.718-8.69 19.406-19.407 19.406m0 18.545a4.472 4.472 0 1 1 0-8.943 4.472 4.472 0 0 1 0 8.943M43.254 91.191a4.471 4.471 0 1 1-6.324-6.323 4.471 4.471 0 0 1 6.324 6.323m-6.325-53.672a4.473 4.473 0 0 1 6.324 0 4.472 4.472 0 1 1-6.323 0m-6.645 31.308a4.472 4.472 0 1 1 .001-8.944 4.472 4.472 0 0 1 0 8.944m33.48-42.424a4.472 4.472 0 1 1 0 8.944 4.472 4.472 0 0 1 0-8.944M84.278 37.52a4.471 4.471 0 1 1 6.324 6.323 4.471 4.471 0 0 1-6.324-6.323m26.103 26.836c0-25.745-20.87-46.615-46.615-46.615S17.15 38.61 17.15 64.355s20.87 46.616 46.615 46.616S110.38 90.1 110.38 64.355",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M131.215 161.576c.304 1.772.471 3.592.472 5.451-.004 17.638-14.306 31.932-31.944 31.928l.008-29.883.002-15.876a54.261 54.261 0 0 0 24.932-6.118 31.777 31.777 0 0 1 6.53 14.498zm15.201-35.288a53.52 53.52 0 0 0 7.149-24.666 31.776 31.776 0 0 1 15.816 1.595 32.06 32.06 0 0 1 4.953 2.314c15.275 8.82 20.507 28.35 11.689 43.624l-39.607-22.867zM68.318 37.133a32.112 32.112 0 0 1-.472-5.45C67.85 14.045 82.151-.25 99.788-.246l-.006 29.884-.004 15.876a54.253 54.253 0 0 0-24.932 6.118 31.78 31.78 0 0 1-6.528-14.499zm78.088 35.323A53.477 53.477 0 0 0 128.654 53.9a31.782 31.782 0 0 1 9.29-12.885 32.129 32.129 0 0 1 4.481-3.13c15.28-8.811 34.809-3.569 43.62 11.71L160.16 64.522l-13.753 7.933zm-93.29-.034a53.521 53.521 0 0 0-7.149 24.666 31.78 31.78 0 0 1-15.816-1.595A32.057 32.057 0 0 1 25.2 93.18C9.924 84.36 4.69 64.83 13.509 49.555l39.607 22.867zm.01 53.832a53.486 53.486 0 0 0 17.753 18.556 31.79 31.79 0 0 1-9.29 12.884 32.1 32.1 0 0 1-4.483 3.13c-15.279 8.813-34.807 3.57-43.619-11.708l25.886-14.93 13.753-7.932z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.WIND
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 150" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(89 92)",
                                d: "M12.439.43a4.085 4.085 0 0 0-3.644 0L4.381 2.67 0 107.93h21.236L16.855 2.662 12.439.43z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M102.183 77.699c-.776.776-1.84 1.227-2.901 1.227-1.064 0-2.125-.451-2.902-1.227a4.114 4.114 0 0 1-1.185-2.86c0-1.103.449-2.125 1.185-2.9 1.553-1.513 4.291-1.513 5.803 0a4.043 4.043 0 0 1 1.186 2.9c0 1.062-.41 2.126-1.186 2.86zm61.53 27.896l-54.342-35.32a4.082 4.082 0 0 1-1.822-3.157l-3.472-64.766A2.484 2.484 0 0 0 101.597 0h-4.615a2.485 2.485 0 0 0-2.481 2.352l-3.472 64.766a4.086 4.086 0 0 1-1.822 3.157l-54.08 35.318a2.483 2.483 0 0 0-.794 3.323l2.275 3.942a2.484 2.484 0 0 0 3.278.973l57.58-29.251a4.079 4.079 0 0 1 3.644 0l57.853 29.257a2.485 2.485 0 0 0 3.274-.975l2.274-3.942a2.484 2.484 0 0 0-.798-3.325z",
                              },
                            }),
                          ]
                        )
                      : result.type === "shape-walkie-talkie"
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M73.083 36.469a2.007 2.007 0 0 0-2.007 2.006v28.624l4.014-.08V38.474a2.006 2.006 0 0 0-2.006-2.006h-.001zm2.928 59.277h38.623v-4.854H76.011v4.854zm0 18.841h38.623v-4.854H76.011v4.854zm0 18.84h38.623v-4.853H76.011v4.854zm19.312 43.437c-6.069 0-11.008-4.939-11.008-11.008 0-6.069 4.94-11.007 11.008-11.007 6.069 0 11.007 4.938 11.007 11.007s-4.938 11.008-11.007 11.008zm23.498-105.83H71.824c-7.203 0-13.066 5.86-13.066 13.064v95.846c0 7.199 5.863 13.056 13.066 13.056h47c7.203 0 13.065-5.857 13.065-13.056V84.098c-.002-7.204-5.862-13.064-13.068-13.064z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M73.083 36.469a2.007 2.007 0 0 0-2.007 2.006v28.624l4.014-.08V38.474a2.006 2.006 0 0 0-2.006-2.006h-.001zm2.928 59.277h38.623v-4.854H76.011v4.854zm0 18.841h38.623v-4.854H76.011v4.854zm0 18.84h38.623v-4.853H76.011v4.854zm19.312 43.437c-6.069 0-11.008-4.939-11.008-11.008 0-6.069 4.94-11.007 11.008-11.007 6.069 0 11.007 4.938 11.007 11.007s-4.938 11.008-11.007 11.008zm23.498-105.83H71.824c-7.203 0-13.066 5.86-13.066 13.064v95.846c0 7.199 5.863 13.056 13.066 13.056h47c7.203 0 13.065-5.857 13.065-13.056V84.098c-.002-7.204-5.862-13.064-13.068-13.064zM80.807 33.017a2.147 2.147 0 0 0 1.519-3.665c-5.097-5.097-13.389-5.097-18.485 0a2.147 2.147 0 1 0 3.036 3.036c3.422-3.422 8.99-3.422 12.411 0 .42.419.97.629 1.52.629zm9.694-13.52c.42.419.969.629 1.518.629a2.147 2.147 0 0 0 1.519-3.665C88.081 11.005 80.817 8 73.083 8c-7.736 0-14.999 3.005-20.455 8.46a2.147 2.147 0 1 0 3.037 3.037c4.644-4.644 10.83-7.202 17.418-7.202 6.586 0 12.773 2.557 17.417 7.202zm-29.23 6.446c3.149-3.15 7.345-4.884 11.812-4.884a16.59 16.59 0 0 1 11.812 4.884c.419.419.968.629 1.518.629s1.1-.21 1.518-.63a2.148 2.148 0 0 0 0-3.037c-3.96-3.96-9.234-6.14-14.848-6.14-5.615 0-10.888 2.18-14.849 6.14a2.148 2.148 0 0 0 3.037 3.038z",
                              },
                            }),
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                d: "M95.323 160.32a5.54 5.54 0 0 0-5.536 5.536 5.542 5.542 0 0 0 5.536 5.536 5.543 5.543 0 0 0 5.536-5.536 5.542 5.542 0 0 0-5.536-5.536z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.BIOGAZ
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 24 24" },
                          },
                          [
                            _c("rect", {
                              attrs: {
                                fill: "#BDD52A",
                                x: "0.2",
                                y: "13",
                                width: "23.8",
                                height: "11",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#415B68",
                                d: "M13.4,1.2V0h-3v1.2C3.4,1.7,0,6.6,0,6.6V24h24V6.6C24,6.6,20.8,1.6,13.4,1.2z M23.4,23.4H0.6V7.2h22.8V23.4z",
                              },
                            }),
                            _c(
                              "g",
                              { attrs: { transform: "translate(0, 3.5)" } },
                              [
                                _c("g", { attrs: { fill: "#00A8FF" } }, [
                                  _c(
                                    "g",
                                    {
                                      attrs: { transform: "translate(12, 12)" },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M-2.4,0.9c0-0.2,0-0.3,0.1-0.3l1.1-0.4l-2.7-1.3l-1.4,2.8l1-0.4c0.1-0.1,0.2,0,0.3,0.1c0.5,1,1.2,1.8,2.2,2.2 C-1.1,3.9-0.2,4,0.8,3.8C0.1,3.7-0.5,3.4-1,3C-1.7,2.4-2.2,1.7-2.4,0.9z",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M4.3-1.3C4.2-1.2,4.1-1.3,4-1.4c-0.5-1-1.2-1.8-2.2-2.2C1.1-3.9,0.2-4-0.8-3.8C-0.1-3.7,0.5-3.4,1-3 c0.7,0.6,1.2,1.3,1.4,2.1c0.1,0.2,0,0.3-0.1,0.3L1.2-0.2l2.7,1.3l1.4-2.8L4.3-1.3z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("td", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(result.name)),
                  ]),
                  result.onError === false
                    ? _c(
                        "td",
                        { staticClass: "valide-text", attrs: { scope: "row" } },
                        [_vm._v(_vm._s(result.lastActivity))]
                      )
                    : result.onError === null
                    ? _c(
                        "td",
                        {
                          staticClass: "warning-text",
                          attrs: { scope: "row" },
                        },
                        [_vm._v(_vm._s(result.lastActivity))]
                      )
                    : _c(
                        "td",
                        { staticClass: "error-text", attrs: { scope: "row" } },
                        [_vm._v(_vm._s(result.lastActivity))]
                      ),
                  result.onError === false
                    ? _c("td", {
                        staticClass: "tab-result-mark valide-mark",
                        attrs: { scope: "row" },
                      })
                    : result.onError === null
                    ? _c("td", {
                        staticClass: "tab-result-mark warning-mark",
                        attrs: { scope: "row" },
                      })
                    : _c("td", {
                        staticClass: "tab-result-mark error-mark",
                        attrs: { scope: "row" },
                      }),
                  _c("td", { attrs: { scope: "row" } }, [
                    _c("input", {
                      staticClass: "button is-xsmall is-danger",
                      attrs: {
                        type: "button",
                        value: "-",
                        title: "remove this site to selected table",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeSite(index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "result-list global-list" }, [
          _c("table", { staticClass: "table" }, [
            _c("caption", [_vm._v("All sites availability")]),
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.globalResultListDisplayed, function (result, index) {
                return _c("tr", { key: result.name }, [
                  _c("td", { attrs: { scope: "row" } }, [
                    result.type === _vm.siteType.SOLAR
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(26 144)",
                                d: "M6.584.232l-6 13.437h146.83l-6-13.437z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                id: "rotor",
                                d: "M74.667 101.31h50.666c.324 0 .572-.34.525-.717l-2.42-19.403c-.04-.307-.261-.535-.525-.535H77.087c-.264 0-.487.228-.525.535l-2.42 19.403c-.048.377.2.717.525.717zm-60.13 0h50.609c.253 0 .472-.213.52-.507l3.21-19.402c.064-.386-.187-.746-.521-.746H22.588c-.203 0-.388.137-.478.351l-8.05 19.402c-.172.416.085.902.478.902zm10.579-25.495h44.247c.254 0 .473-.212.522-.507L72.34 60.47c.063-.387-.188-.747-.522-.747H31.272c-.203 0-.387.137-.477.352l-6.156 14.837c-.173.416.085.902.477.902zm34.966 58.745l4.333-26.193c.064-.387-.186-.747-.521-.747H11.399c-.202 0-.387.136-.476.35L.055 134.166c-.173.416.084.902.476.902h59.03c.253 0 .472-.213.52-.507zm139.863-.395l-10.868-26.194c-.09-.215-.274-.35-.477-.35h-52.495c-.334 0-.584.36-.521.746l4.334 26.193c.049.294.267.507.52.507h59.03c.392 0 .65-.486.477-.902zM33.382 55.894h39.277c.254 0 .473-.213.522-.506l1.925-11.641c.065-.387-.187-.747-.521-.747H38.212c-.203 0-.39.137-.478.351l-4.83 11.642c-.173.415.084.9.478.9zm94.278 4.577l2.454 14.837c.049.295.268.507.522.507h44.248c.392 0 .649-.486.476-.902l-6.156-14.837c-.09-.215-.274-.352-.476-.352h-40.546c-.334 0-.585.36-.522.747zm3.463 20.93l3.21 19.402c.049.294.268.507.522.507h50.607c.393 0 .649-.486.476-.902l-8.05-19.402c-.088-.214-.274-.351-.477-.351h-45.766c-.334 0-.585.36-.522.746zm-4.847 26.22H73.724c-.264 0-.488.227-.526.535l-3.267 26.193c-.047.378.2.718.525.718h59.088c.324 0 .572-.34.525-.718l-3.267-26.193c-.038-.308-.262-.536-.526-.536zM80.332 55.893h39.336c.324 0 .573-.34.525-.717l-1.452-11.642c-.038-.307-.261-.535-.525-.535H81.783c-.262 0-.486.228-.525.535l-1.452 11.642c-.047.377.202.717.526.717zm-2.485 19.92h44.305c.325 0 .574-.339.527-.717l-1.852-14.837c-.038-.307-.262-.536-.525-.536H79.697c-.263 0-.487.229-.525.536L77.32 75.097c-.047.378.2.718.526.718zm49.494-19.92h39.277c.393 0 .65-.486.477-.901l-4.83-11.642c-.09-.214-.274-.351-.477-.351h-36.374c-.334 0-.585.36-.52.747l1.925 11.64c.049.294.267.507.522.507z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.HYGRO
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(36 35)",
                                d: "M92.774 64.355a4.472 4.472 0 1 1 8.944 0 4.472 4.472 0 0 1-8.944 0M90.602 91.19a4.47 4.47 0 0 1-6.323 0 4.47 4.47 0 0 1 0-6.324 4.471 4.471 0 0 1 6.322 6.323m-26.835-7.43c-10.719 0-19.407-8.687-19.407-19.405 0-10.718 8.688-19.406 19.407-19.406 10.718 0 19.407 8.688 19.407 19.406 0 10.718-8.69 19.406-19.407 19.406m0 18.545a4.472 4.472 0 1 1 0-8.943 4.472 4.472 0 0 1 0 8.943M43.254 91.191a4.471 4.471 0 1 1-6.324-6.323 4.471 4.471 0 0 1 6.324 6.323m-6.325-53.672a4.473 4.473 0 0 1 6.324 0 4.472 4.472 0 1 1-6.323 0m-6.645 31.308a4.472 4.472 0 1 1 .001-8.944 4.472 4.472 0 0 1 0 8.944m33.48-42.424a4.472 4.472 0 1 1 0 8.944 4.472 4.472 0 0 1 0-8.944M84.278 37.52a4.471 4.471 0 1 1 6.324 6.323 4.471 4.471 0 0 1-6.324-6.323m26.103 26.836c0-25.745-20.87-46.615-46.615-46.615S17.15 38.61 17.15 64.355s20.87 46.616 46.615 46.616S110.38 90.1 110.38 64.355",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M131.215 161.576c.304 1.772.471 3.592.472 5.451-.004 17.638-14.306 31.932-31.944 31.928l.008-29.883.002-15.876a54.261 54.261 0 0 0 24.932-6.118 31.777 31.777 0 0 1 6.53 14.498zm15.201-35.288a53.52 53.52 0 0 0 7.149-24.666 31.776 31.776 0 0 1 15.816 1.595 32.06 32.06 0 0 1 4.953 2.314c15.275 8.82 20.507 28.35 11.689 43.624l-39.607-22.867zM68.318 37.133a32.112 32.112 0 0 1-.472-5.45C67.85 14.045 82.151-.25 99.788-.246l-.006 29.884-.004 15.876a54.253 54.253 0 0 0-24.932 6.118 31.78 31.78 0 0 1-6.528-14.499zm78.088 35.323A53.477 53.477 0 0 0 128.654 53.9a31.782 31.782 0 0 1 9.29-12.885 32.129 32.129 0 0 1 4.481-3.13c15.28-8.811 34.809-3.569 43.62 11.71L160.16 64.522l-13.753 7.933zm-93.29-.034a53.521 53.521 0 0 0-7.149 24.666 31.78 31.78 0 0 1-15.816-1.595A32.057 32.057 0 0 1 25.2 93.18C9.924 84.36 4.69 64.83 13.509 49.555l39.607 22.867zm.01 53.832a53.486 53.486 0 0 0 17.753 18.556 31.79 31.79 0 0 1-9.29 12.884 32.1 32.1 0 0 1-4.483 3.13c-15.279 8.813-34.807 3.57-43.619-11.708l25.886-14.93 13.753-7.932z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.WIND
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 150" },
                          },
                          [
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                transform: "translate(89 92)",
                                d: "M12.439.43a4.085 4.085 0 0 0-3.644 0L4.381 2.67 0 107.93h21.236L16.855 2.662 12.439.43z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M102.183 77.699c-.776.776-1.84 1.227-2.901 1.227-1.064 0-2.125-.451-2.902-1.227a4.114 4.114 0 0 1-1.185-2.86c0-1.103.449-2.125 1.185-2.9 1.553-1.513 4.291-1.513 5.803 0a4.043 4.043 0 0 1 1.186 2.9c0 1.062-.41 2.126-1.186 2.86zm61.53 27.896l-54.342-35.32a4.082 4.082 0 0 1-1.822-3.157l-3.472-64.766A2.484 2.484 0 0 0 101.597 0h-4.615a2.485 2.485 0 0 0-2.481 2.352l-3.472 64.766a4.086 4.086 0 0 1-1.822 3.157l-54.08 35.318a2.483 2.483 0 0 0-.794 3.323l2.275 3.942a2.484 2.484 0 0 0 3.278.973l57.58-29.251a4.079 4.079 0 0 1 3.644 0l57.853 29.257a2.485 2.485 0 0 0 3.274-.975l2.274-3.942a2.484 2.484 0 0 0-.798-3.325z",
                              },
                            }),
                          ]
                        )
                      : result.type === "shape-walkie-talkie"
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 200 200" },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M73.083 36.469a2.007 2.007 0 0 0-2.007 2.006v28.624l4.014-.08V38.474a2.006 2.006 0 0 0-2.006-2.006h-.001zm2.928 59.277h38.623v-4.854H76.011v4.854zm0 18.841h38.623v-4.854H76.011v4.854zm0 18.84h38.623v-4.853H76.011v4.854zm19.312 43.437c-6.069 0-11.008-4.939-11.008-11.008 0-6.069 4.94-11.007 11.008-11.007 6.069 0 11.007 4.938 11.007 11.007s-4.938 11.008-11.007 11.008zm23.498-105.83H71.824c-7.203 0-13.066 5.86-13.066 13.064v95.846c0 7.199 5.863 13.056 13.066 13.056h47c7.203 0 13.065-5.857 13.065-13.056V84.098c-.002-7.204-5.862-13.064-13.068-13.064z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#425B67",
                                d: "M73.083 36.469a2.007 2.007 0 0 0-2.007 2.006v28.624l4.014-.08V38.474a2.006 2.006 0 0 0-2.006-2.006h-.001zm2.928 59.277h38.623v-4.854H76.011v4.854zm0 18.841h38.623v-4.854H76.011v4.854zm0 18.84h38.623v-4.853H76.011v4.854zm19.312 43.437c-6.069 0-11.008-4.939-11.008-11.008 0-6.069 4.94-11.007 11.008-11.007 6.069 0 11.007 4.938 11.007 11.007s-4.938 11.008-11.007 11.008zm23.498-105.83H71.824c-7.203 0-13.066 5.86-13.066 13.064v95.846c0 7.199 5.863 13.056 13.066 13.056h47c7.203 0 13.065-5.857 13.065-13.056V84.098c-.002-7.204-5.862-13.064-13.068-13.064zM80.807 33.017a2.147 2.147 0 0 0 1.519-3.665c-5.097-5.097-13.389-5.097-18.485 0a2.147 2.147 0 1 0 3.036 3.036c3.422-3.422 8.99-3.422 12.411 0 .42.419.97.629 1.52.629zm9.694-13.52c.42.419.969.629 1.518.629a2.147 2.147 0 0 0 1.519-3.665C88.081 11.005 80.817 8 73.083 8c-7.736 0-14.999 3.005-20.455 8.46a2.147 2.147 0 1 0 3.037 3.037c4.644-4.644 10.83-7.202 17.418-7.202 6.586 0 12.773 2.557 17.417 7.202zm-29.23 6.446c3.149-3.15 7.345-4.884 11.812-4.884a16.59 16.59 0 0 1 11.812 4.884c.419.419.968.629 1.518.629s1.1-.21 1.518-.63a2.148 2.148 0 0 0 0-3.037c-3.96-3.96-9.234-6.14-14.848-6.14-5.615 0-10.888 2.18-14.849 6.14a2.148 2.148 0 0 0 3.037 3.038z",
                              },
                            }),
                            _c("path", {
                              staticClass: "blue",
                              attrs: {
                                d: "M95.323 160.32a5.54 5.54 0 0 0-5.536 5.536 5.542 5.542 0 0 0 5.536 5.536 5.543 5.543 0 0 0 5.536-5.536 5.542 5.542 0 0 0-5.536-5.536z",
                              },
                            }),
                          ]
                        )
                      : result.type === _vm.siteType.BIOGAZ
                      ? _c(
                          "svg",
                          {
                            staticClass: "material-icons",
                            attrs: { viewBox: "0 0 24 24" },
                          },
                          [
                            _c("rect", {
                              attrs: {
                                fill: "#BDD52A",
                                x: "0.2",
                                y: "13",
                                width: "23.8",
                                height: "11",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#415B68",
                                d: "M13.4,1.2V0h-3v1.2C3.4,1.7,0,6.6,0,6.6V24h24V6.6C24,6.6,20.8,1.6,13.4,1.2z M23.4,23.4H0.6V7.2h22.8V23.4z",
                              },
                            }),
                            _c(
                              "g",
                              { attrs: { transform: "translate(0, 3.5)" } },
                              [
                                _c("g", { attrs: { fill: "#00A8FF" } }, [
                                  _c(
                                    "g",
                                    {
                                      attrs: { transform: "translate(12, 12)" },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M-2.4,0.9c0-0.2,0-0.3,0.1-0.3l1.1-0.4l-2.7-1.3l-1.4,2.8l1-0.4c0.1-0.1,0.2,0,0.3,0.1c0.5,1,1.2,1.8,2.2,2.2 C-1.1,3.9-0.2,4,0.8,3.8C0.1,3.7-0.5,3.4-1,3C-1.7,2.4-2.2,1.7-2.4,0.9z",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M4.3-1.3C4.2-1.2,4.1-1.3,4-1.4c-0.5-1-1.2-1.8-2.2-2.2C1.1-3.9,0.2-4-0.8-3.8C-0.1-3.7,0.5-3.4,1-3 c0.7,0.6,1.2,1.3,1.4,2.1c0.1,0.2,0,0.3-0.1,0.3L1.2-0.2l2.7,1.3l1.4-2.8L4.3-1.3z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("td", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(result.name)),
                  ]),
                  result.onError === false
                    ? _c(
                        "td",
                        { staticClass: "valide-text", attrs: { scope: "row" } },
                        [_vm._v(_vm._s(result.lastActivity))]
                      )
                    : result.onError === null
                    ? _c(
                        "td",
                        {
                          staticClass: "warning-text",
                          attrs: { scope: "row" },
                        },
                        [_vm._v(_vm._s(result.lastActivity))]
                      )
                    : _c(
                        "td",
                        { staticClass: "error-text", attrs: { scope: "row" } },
                        [_vm._v(_vm._s(result.lastActivity))]
                      ),
                  result.onError === false
                    ? _c("td", {
                        staticClass: "tab-result-mark valide-mark",
                        attrs: { scope: "row" },
                      })
                    : result.onError === null
                    ? _c("td", {
                        staticClass: "tab-result-mark warning-mark",
                        attrs: { scope: "row" },
                      })
                    : _c("td", {
                        staticClass: "tab-result-mark error-mark",
                        attrs: { scope: "row" },
                      }),
                  _c("td", { attrs: { scope: "row" } }, [
                    _c("input", {
                      staticClass: "button is-xsmall is-success",
                      attrs: {
                        type: "button",
                        value: "+",
                        title: "Add this site to selected table",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectSite(index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Site name")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Last activity (utc)")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Availability")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Action")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }