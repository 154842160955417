const arrayUtilities = {
    AddStringTagIfNotPresent (baseArray, tag) {
        let resultTagArray = baseArray;
        if(baseArray.indexOf(tag) === -1) {
            resultTagArray.push(tag);           
        }
        return resultTagArray;
    },
    MergeStringTagArray (baseArray, arraytoAdd) {
        let resultTagArray = baseArray;
        arraytoAdd.forEach(element => {
            if(baseArray.indexOf(element) === -1) {
                resultTagArray.push(element);           
            }
        });
        return resultTagArray;
    },
    MergeArrayWithDistinct (baseArray, arraytoAdd) {
        let resultTagArray = baseArray;
        arraytoAdd.forEach(element => {
            if(baseArray.map(m => m.preprocTag.name).indexOf(element.preprocTag.name) === -1) {
                resultTagArray.push(element);           
            }
        });
        return resultTagArray;
    },
    AddSiteAvailabilitygIfNotPresent (baseArray, site) {
        let resultSiteArray = baseArray;
        if(baseArray.map(m => m.name).indexOf(site.name) === -1) {
            resultSiteArray.push(site);           
        }
        return resultSiteArray;
    },
}

export default arrayUtilities;
