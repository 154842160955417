import { DateTime as LuxonDateTime } from "luxon";

const dateTimeLuxon = {
    nowBaseFormat() {
        return LuxonDateTime.fromJSDate(new Date(), { zone: "utc" }).setLocale('fr');//.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric', timeZoneName: 'short'});
    },
    baseFormat(currentDate) {
        return LuxonDateTime.fromJSDate(new Date(currentDate), { zone: "utc" }).setLocale('fr');//.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric', timeZoneName: 'short'});
    }
}
export default dateTimeLuxon;