var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "body-template-root" },
    [
      _c("app-header"),
      _c("section", { staticClass: "main-section section" }, [
        _c("div", { staticClass: "container content" }, [_c("router-view")], 1),
      ]),
      _c("notifications", {
        attrs: { position: "top right", width: "500px", group: "foo" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }