<template>
  <nav v-if="authenticated" class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <p class="navbar-item" id="projetc-title">
        IoT Monitoring
      </p>

      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/redismonitor">
            Redis Monitor
          </router-link>
          <router-link class="navbar-item" to="/sitesavailabilities">
            Sites Availabilities
          </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <button class="button is-danger" v-if='authenticated' v-on:click='logout' id='logout-button'> Logout </button>
          <button class="button is-success" v-else v-on:click='login' id='login-button'> Login </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  data: function () {
    return {
      authenticated: false
    }
  },
  created () {
    this.isAuthenticated();
  },
  watch: {
    // Everytime the route changes, check for auth status
    '$route': 'isAuthenticated'
  },
  methods: {
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated();
    },
    login () {
      this.$auth.loginRedirect('/redismonitor');
    },
    async logout () {
      await this.$auth.logout();
      await this.isAuthenticated();

      this.$router.push({ path: '/LogoutItem' });
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #287ab1;

.navbar {
  width: 100%;
  border-bottom: 1px solid $primary;

  #projetc-title {
  background-color: $primary;
  color: #ffffff;
  font-weight: bold;
  }
}
</style>
