var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.authenticated
    ? _c(
        "nav",
        {
          staticClass: "navbar",
          attrs: { role: "navigation", "aria-label": "main navigation" },
        },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "navbar-menu", attrs: { id: "navbarBasicExample" } },
            [
              _c(
                "div",
                { staticClass: "navbar-start" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "navbar-item",
                      attrs: { to: "/redismonitor" },
                    },
                    [_vm._v(" Redis Monitor ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "navbar-item",
                      attrs: { to: "/sitesavailabilities" },
                    },
                    [_vm._v(" Sites Availabilities ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "navbar-end" }, [
                _c("div", { staticClass: "navbar-item" }, [
                  _c("div", { staticClass: "buttons" }, [
                    _vm.authenticated
                      ? _c(
                          "button",
                          {
                            staticClass: "button is-danger",
                            attrs: { id: "logout-button" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v(" Logout ")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "button is-success",
                            attrs: { id: "login-button" },
                            on: { click: _vm.login },
                          },
                          [_vm._v(" Login ")]
                        ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-brand" }, [
      _c("p", { staticClass: "navbar-item", attrs: { id: "projetc-title" } }, [
        _vm._v(" IoT Monitoring "),
      ]),
      _c(
        "a",
        {
          staticClass: "navbar-burger burger",
          attrs: {
            role: "button",
            "aria-label": "menu",
            "aria-expanded": "false",
            "data-target": "navbarBasicExample",
          },
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _c("span", { attrs: { "aria-hidden": "true" } }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }